export * as P from "./Props";
export * as TAG from "./Tags";
export * as MISC from "./Misc";
export * as APIS from "./apis";
export * as REGEX from "./regex";
export * as ROLES from "./Roles";
export * as COVID from "./Covid";
export * as DS from "./Datasets";
export * as EC from "./ErrorCode";
export * as TABS from "./TabMenu";
export * as FP from "./FormPaths";
export * as PKG from "./Packages";
export * as LM from "./linkMaker";
export * as RIGHTS from "./Rights";
export * as PANELS from "./Panels";
export * as REM from "./remarques";
export * as TICKET from "./Tickets";
export * as AA from "./assetAffect";
export * as LANG from "./languages";
export * as PT from "./ParkingTypes";
export * as REGIONS from "./Regions";
export * as CT from "./ContractTypes";
export * as LT from "./LinkTypesCode";
export * as MISSION from "./Missions";
export * as ICONS from "./IconsLabels";
export * as DLT from "./DataLoadTypes";
export * as MO from "./matrixOperation";
export * as FREQ from "./frequencyCode";
export * as RESOURCE from "./Resources";
export * as GC from "./GrafanaConstants";
export * as TSC from "./TableStatesCodes";
export * as GAMMES from "./EquipGammeDefaults";