import React from "react";
import * as I from "../Input";
import * as H from "../../../hooks";
import { Flex, Spinner } from "../../Layout";
import { Select } from "../Components";
import * as S from "../../../services";
import { T, TB, TC } from "../../../Constants";

type Option = GammeOption & GammeSelectProps["options"][number];
type GammeOption = ReturnType<T.API.Utils.Gammes.GetGammesOptions>[number];
export type GammeSelectProps = I.InputProps & Partial<Record<"local_controlled", boolean>>;

const GammeSelect: React.FC<GammeSelectProps> = ({ onChange, ...props }) => {
    const lg = H.useLanguage();
    const [options, set_options, status] = H.useAsyncState<Option[]>([]);
    const [local_value, set_local_value] = React.useState<string | null>(null);
    const isDisabled = React.useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);

    const value = React.useMemo(() => {
        if (props.local_controlled) return local_value;
        else return props.value;
    }, [props.local_controlled, props.value, local_value]);

    const gammesTree = React.useMemo(() => {
        let currentGamme = options.filter(o => o.value === value)[0];
        if (!currentGamme) return [];

        let tree: typeof options = [];
        const getParent = (option: Option) => {
            let parent = options.filter(o => o.value === option.parent)[0];
            if (parent) {
                tree.push(parent);
                getParent(parent);
            }
        }

        getParent(currentGamme);
        return tree.reverse().concat(currentGamme);
    }, [value, options]);

    const getOptions = React.useCallback((value: string) => {
        let currentValue = options.filter(o => o.value === value)[0];
        if (!currentValue) return options;

        const findDescendants = (ids: T.AllowArray<string>): typeof options => {
            ids = TB.arrayWrapper(ids);
            let descendants = options.filter(o => ids.includes(o.parent));

            if (descendants.length > 0) return descendants
                .concat(findDescendants(descendants.map(d => d.value)));
            return [];
        }

        return findDescendants(value);
    }, [options]);

    const updateValue = React.useCallback((id: string) => {
        if (props.local_controlled) set_local_value(id);
        onChange?.(id)
    }, [onChange, props.local_controlled]);

    const currentHasChildren = React.useMemo(() => {
        let current = options.filter(o => o.value === value)[0];
        if (!current) return true;
        return options.some(o => o.parent === value);
    }, [value, options]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getGammesOptions({ lang: lg.prop })
            .then(({ data }) => isSubscribed && set_options(data, "done"))
            .catch(() => isSubscribed && set_options([], "error"));
        return () => {
            isSubscribed = false;
            set_options([], "load");
        }
    }, [set_options, lg.prop]);

    const renderItem = React.useCallback((option: Option) => <Flex alignItems="center" justifyContent="between">
        <span>
            {option.isEquipment && <i className="fa fa-wrench me-2" />}
            {option.label}
        </span>
        <span className="text-muted fs-85 ms-2" children={option.omniclass} />
    </Flex>, []);

    return <Spinner status={status} spin_text={TC.GAMME_FORM_COMP_LOADING} min_load_size="200px">
        <I.ComponentWrapper {...props} disabled={isDisabled}>
            {gammesTree.map(g => <Select
                key={g.value}
                positionFixed
                value={g.value}
                disabled={isDisabled}
                options={getOptions(g.parent)}
                onChange={id => updateValue(id || g.parent)}
                typeahead={{ extra_search_props: "omniclass", renderItem }}
            />)}

            {currentHasChildren && <Select
                key={value}
                positionFixed
                disabled={isDisabled}
                onChange={updateValue}
                options={getOptions(value)}
                typeahead={{ extra_search_props: "omniclass", renderItem }}
            />}
        </I.ComponentWrapper >
    </Spinner>
};

export default GammeSelect;