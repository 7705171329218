import React from "react";

const Maintenance: React.FC = () => <div style={{ height: "100vh", textAlign: "center", padding: "150px", font: "20px Helvetica, sans-serif", color: '#333' }}>
    <article style={{ display: "block", textAlign: "left", width: "650px", margin: '0 auto' }}>
        <h1 style={{ fontSize: "50px" }}>Nous serons de retour bientôt</h1>
        <div>
            <p>Désolé pour la gêne occasionnée, mais nous effectuons actuellement une mise à jour.</p>
        </div>
    </article>
</div>;

export default Maintenance;