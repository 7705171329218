import React from "react";

type Effect = (nbRenders: number) => ReturnType<Parameters<typeof React.useEffect>[0]>;

/**
 * A variant of useEffect, with access to the number of times the effect has been called
 */
const useCountedEffect = (effect: Effect, deps?: React.DependencyList) => {
    const renderCount = React.useRef(0);

    React.useEffect(() => {
        const cleanup = effect?.(renderCount.current);
        renderCount.current++;
        if (typeof cleanup === "function") return cleanup;
    }, [effect, deps]);
};

export default useCountedEffect;