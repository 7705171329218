// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event {
  overflow: hidden;
  box-shadow: 0 0 6px rgba(38, 38, 38, 0.2);
  margin: 10px;
  border-radius: 5px;
}
.event.separated {
  display: grid;
  grid-template-columns: 10% auto;
}
.event .trash {
  margin: auto;
  cursor: pointer;
}
.event .trash:hover {
  color: red;
}
.event .accordion-header_2 {
  font-weight: bolder;
  padding-left: 5px;
}
.event .accordion-body {
  padding-bottom: 2px;
}
.event .value {
  margin-left: 20px;
  margin-top: 0px;
  margin-bottom: 2px;
  font-size: 15px;
  color: #262626;
}
.event .line {
  display: grid;
  grid-template-rows: auto auto;
}
.event .line h6 {
  margin-bottom: 0;
}
.event .line h6,
.event .line .value {
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/Components/Calendar/Event/Event.scss","webpack://./src/variable.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,yCCkBW;EDjBX,YAAA;EACA,kBAAA;AADF;AAGE;EACE,aAAA;EACA,+BAAA;AADJ;AAIE;EACE,YAAA;EACA,eAAA;AAFJ;AAGI;EACE,UAAA;AADN;AAKE;EACE,mBAAA;EACA,iBAAA;AAHJ;AAKE;EACE,mBAAA;AAHJ;AAKE;EACE,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;EACA,cCdI;ADWR;AAKE;EACE,aAAA;EACA,6BAAA;AAHJ;AAII;EACE,gBAAA;AAFN;AAII;;EAEE,mBAAA;AAFN","sourcesContent":["@import \"src/variable\";\r\n\r\n.event {\r\n  overflow: hidden;\r\n  box-shadow: $btn-shadow;\r\n  margin: 10px;\r\n  border-radius: 5px;\r\n\r\n  &.separated {\r\n    display: grid;\r\n    grid-template-columns: 10% auto;\r\n  }\r\n\r\n  .trash {\r\n    margin: auto;\r\n    cursor: pointer;\r\n    &:hover {\r\n      color: red;\r\n    }\r\n  }\r\n\r\n  & .accordion-header_2 {\r\n    font-weight: bolder;\r\n    padding-left: 5px;\r\n  }\r\n  & .accordion-body {\r\n    padding-bottom: 2px;\r\n  }\r\n  .value {\r\n    margin-left: 20px;\r\n    margin-top: 0px;\r\n    margin-bottom: 2px;\r\n    font-size: 15px;\r\n    color: $black;\r\n  }\r\n  .line {\r\n    display: grid;\r\n    grid-template-rows: auto auto;\r\n    h6 {\r\n      margin-bottom: 0;\r\n    }\r\n    h6,\r\n    .value {\r\n      white-space: nowrap;\r\n    }\r\n  }\r\n}","$base-white: #ebf0fa;\r\n$base-light: #8cb3d9;\r\n$base: #3973ac;\r\n$base-dark: #264d73;\r\n\r\n$bleu: #00aaff;\r\n$bleu-dark: #0099e6;\r\n$purple: #9999ff;\r\n$purple-dark: #8080ff;\r\n$red: #ff6666;\r\n$red-dark: #ff4d4d;\r\n$yellow: #ffcc66;\r\n$yellow-dark: #ffc34d;\r\n$green: #00cc66;\r\n$green-dark: #00b359;\r\n\r\n$white: #ffffff;\r\n$grey-light: #bfbfbf;\r\n$grey: #909090;\r\n$black: #262626;\r\n\r\n$cart-shadow: 0 0 7px rgba($black, 0.3);\r\n$btn-shadow: 0 0 6px rgba($black, 0.2);\r\n$bigBox-shadow : 0 0 22px rgba($black, 0.3);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
