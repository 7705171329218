// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardMain {
  width: 50%;
  min-width: 300px;
  margin: 5% auto;
  height: 70vh;
  box-shadow: 0 0 7px rgba(38, 38, 38, 0.3);
  background-color: white;
  border-radius: 1.5%;
}
.cardMain .card-header {
  font-weight: bold;
  font-size: large;
  text-align: center;
}
.cardMain .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.cardMain .card-body .imgUploader {
  max-height: 30vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cardMain .card-body .imgUploader img {
  max-height: 30vh;
  border: 1px solid black;
  max-width: 50%;
}
.cardMain .card-body .inputPanel {
  width: 70%;
  display: grid;
  grid-template-columns: 30% 55% 15%;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/Events_Tickets_QR/Entity_QR/QR_Entity.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;EACA,yCAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,mBAAA;AAAJ;AAEI;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AAAN;AAEM;EACE,gBAAA;EACA,uBAAA;EACA,cAAA;AAAR;AAII;EACE,UAAA;EACA,aAAA;EACA,kCAAA;EACA,mBAAA;AAFN","sourcesContent":[".cardMain {\n  width: 50%;\n  min-width: 300px;\n  margin: 5% auto;\n  height: 70vh;\n  box-shadow: 0 0 7px rgba(#262626, 0.3);\n  background-color: white;\n  border-radius: 1.5%;\n\n  .card-header {\n    font-weight: bold;\n    font-size: large;\n    text-align: center;\n  }\n\n  .card-body {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-evenly;\n    align-items: center;\n\n    .imgUploader {\n      max-height: 30vh;\n      display: flex;\n      flex-direction: row;\n      justify-content: center;\n      align-items: center;\n\n      img {\n        max-height: 30vh;\n        border: 1px solid black;\n        max-width: 50%;\n      }\n    }\n\n    .inputPanel {\n      width: 70%;\n      display: grid;\n      grid-template-columns: 30% 55% 15%;\n      align-items: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
