import _ from "lodash";
import React from "react";
import moment from "moment";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as CO from "../../../Common";
import * as S from "../../../services";
import * as SBP from "../SideBarPanels";
import { T, TB, TC } from "../../../Constants";
import * as AGCommunity from "ag-grid-community";
import { MenuModule } from '@ag-grid-enterprise/menu';
import { LicenseManager } from "@ag-grid-enterprise/core"
import { Cells as C, ColTypes as COT } from "../AgGridDefs";
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import * as AGR from "@adaptabletools/adaptable-react-aggrid";
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { CellsTypes as CT, ColumnTypesDefault as CTD } from "../AgGridDefs";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";

/** Compilation error when trying to import the normal way, no error this way */
import { Adaptable } from "../../../../node_modules/@adaptabletools/adaptable-react-aggrid/src/components/AdaptableProvider";

import '@adaptabletools/adaptable/index.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

//#region Types
type TData = Record<string, any>;
type Overlay = "loading" | "no_row" | "none";
type ToolPanels = "column" | "filter" | "state";
type GridOptions<A = TData> = AGR.AdaptableProviderProps<A>["gridOptions"];

type GetContextMenuItems<TData> = (params: Omit<Parameters<GridOptions<TData>["getContextMenuItems"]>[0], "defaultItems"> & Record<"defaultItems", ReturnType<GridOptions<TData>["getContextMenuItems"]>>)
    => ReturnType<GridOptions<TData>["getContextMenuItems"]>;

/** Expended ColDef Definition to includes custom render params */
export interface ColDef<A extends TData> extends Omit<AGCommunity.ColDef<A, any>, "field"> {
    /** Overwrite of the 'field' property, to allow more flexibility */
    field?: string | any;
    /** Extra params to provide, depending on the type of the column */
    params?: ColDefParams<A>;
};

/** Expended ColGroupDef Definition to includes the custom ColDef definition */
export interface ColGroupDef<A extends TData> extends Omit<AGCommunity.ColGroupDef<A>, "children"> {
    /** A list of sub-columns */
    children: ColDef<A>[];
};

/** Overwrite of the official type to take the new ColDef definition */
type OnValueChange<A extends TData> = (event: Omit<Parameters<GridOptions<A>["onCellEditRequest"]>[0], "colDef"> & { colDef: ColDef<A> })
    => ReturnType<GridOptions<A>["onCellEditRequest"]>;

/** Extra params for a column, to be used in custom render cell */
export type ColDefParams<A extends TData> = Partial<
    C.Date.Props
    & C.File.Props
    & C.Color.Props
    & C.Number.Props
    & C.QRCode.Props
    & C.Free.Props<A>
    & C.CheckBox.Props
    & C.Pictures.Props
    & C.RoleCheck.Props
    & C.Select.Props<A>
    & C.Button.Props<A>
    & C.RegValidity.Props
    & C.LinkedElem.Props<A>
    & C.SelectButton.Props<A>
    & COT.ColParamsGeneral<A>
>;

export type TableProps<A extends TData> = T.MakeRequired<Omit<GridOptions<A>, "rowData" | "columnDefs" | "getContextMenuItems">, "getRowId"> & {
    /** The status regarding the loading data */
    status?: T.AsyncStates;
    /** Is the data loading ? */
    loading?: boolean;
    /** The columns to fit automatically, if none provided, the all are fitted */
    autoFit?: boolean | T.AllowArray<string>;
    /** Removes the padding at the bottom of the table */
    noBottomPadding?: boolean;
    /** The basic columns */
    columns: (ColDef<A> | ColGroupDef<A>)[];
    /** The content of the sidebar */
    sideBar?: boolean | ToolPanels | "filters_columns";
    /** The id to find the table's state savers */
    origin?: string;
    /** Name of Column in AdapTable guaranteed to contain unique values, necessary for adaptable */
    adaptable_key?: string;
    /** Hide unknown columns when column definition changes */
    remove_unknown_columns?: boolean;
    /** Column default properties */
    columns_base?: "all" | "all_but_edit" | T.AllowArray<"sortable" | "editable" | "filterable" | "grouped">;
    /** Callback for a changed value event */
    onValueChange?: OnValueChange<A>;
    /** Add an export button to the status bar */
    export_dashboard_button?: boolean;
    /** Add a reload button to the status bar, and fire this callback */
    reload_button_cb?: () => void;
    /** The rows data to display */
    rows?: A[];
    /** Add the count of rows in the table, default is bottom */
    count?: boolean | "bottom";
    /** Identifier for this instance of AdapTable, name of the state saved in local storage */
    adaptableId: string;
    /** Callback to use before exporting to excel */
    excel_process?: (value: any, colDef: ColDef<A>, row?: A) => null | string;
    /** Callback for when the table is ready */
    onReadyGrid?: (grid: TableRef<A>["grid"]) => void;
    /** Adaptable Config */
    adaptable?: Partial<AGR.AdaptableOptions>;
    /** Extra buttons on the grid */
    extra_buttons?: T.AllowArray<AGR.DashboardOptions["customDashboardButtons"][number]>;
    /** Extra Toolbars on the grid */
    toolbars?: T.AllowArray<AGR.DashboardOptions["customToolbars"][number]>;
    /** Callback to generate context menu item options */
    getContextMenuItems?: GetContextMenuItems<A>;
    /** The defaults to use for context menu certification */
    certification?: Record<"form" | "item_id_prop", string> & Record<"context", T.ContextParams>;
    /** Provide a default config for Adaptable */
    adaptable_config?: Exclude<AGR.AdaptableOptions["predefinedConfig"], string>;
    /** Auto-Expand row groups */
    autoExpandGroups?: boolean;
    /** Are the rows transfer handled by this component of the parent component ? */
    api_provided_rows?: boolean;
}

export type TableRef<A extends TData> = {
    /** AG-GRID API */
    grid: AGR.AgGridApi<A>;
    /** AdapTable API */
    adaptable: AGR.AdaptableApi<A>;
};
//#endregion

//#region Constants
const MODULES = [
    MenuModule,
    SideBarModule,
    StatusBarModule,
    SetFilterModule,
    ClipboardModule,
    GridChartsModule,
    ExcelExportModule,
    MultiFilterModule,
    RowGroupingModule,
    RangeSelectionModule,
    FiltersToolPanelModule,
    ColumnsToolPanelModule,
    ClientSideRowModelModule,
];

const AdaptableConfigPanel = [
    "GridInfo",
    "ColumnInfo",
    "Dashboard",
    "-",
    "Layout",
    "CalculatedColumn",
    "CustomSort",
    "Alert",
    // "SystemStatus",
    "-",
    // "Export",
    // "ImportData",
    // "-",
    "FormatColumn",
    "StyledColumn",
    "FlashingCell",
    "Theme",
    "-",
    "QuickSearch",
    "GridFilter",
    // "DataSet",
    "NamedQuery",
    "-",
    "PlusMinus",
    "Shortcut",
    // "DataChanges",
    "-",
    "Schedule",
    // "ManageState",
] as AGR.SettingsPanelOptions["navigation"]["items"];

const sidePanel: Record<ToolPanels, SBP.SideBarTool> = {
    ...SBP.Configs,
    column: {
        id: 'columns',
        iconKey: 'columns',
        labelKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        labelDefault: TC.AG_TABLE_COLUMNS_SIDE,
    },
    filter: {
        id: 'filters',
        iconKey: 'filter',
        labelKey: 'filters',
        toolPanel: 'agFiltersToolPanel',
        labelDefault: TC.AG_TABLE_FILTER_SIDE,
    },
};
//#endregion

const RenderTable = <A extends TData,>({ onReadyGrid, onValueChange, excel_process, getContextMenuItems, reload_button_cb, onCellEditingStarted, ...props }: TableProps<A>, ref: React.ForwardedRef<TableRef<A>>) => {
    const isDark = H.useDark();
    const lg = H.useLanguage();
    const grid_ready = H.useBoolean(false);
    const is_licensed = H.useBoolean(false);
    const adaptable_ready = H.useBoolean(false);
    const col_ref = React.useRef(props.columns || []);
    const last_overlay = React.useRef<Overlay>("none");
    const onValueChange_ref = React.useRef(onValueChange);
    const gridContainer = React.useRef<HTMLDivElement>(null);
    const adaptable = React.useRef<TableRef<A>["adaptable"]>(null);

    React.useImperativeHandle(ref, () => ({ grid: adaptable.current?.agGridApi, adaptable: adaptable.current }));
    const ready = React.useMemo(() => grid_ready.value && adaptable_ready.value, [grid_ready.value, adaptable_ready.value]);

    React.useEffect(() => {
        let isSubscribed = true;
        // Load and apply the Ag-Grid LicenseKey
        if (!is_licensed.value) S.agGridToken().then(({ data }) => {
            let key = TB.decode_grid_token(data);
            LicenseManager.setLicenseKey(key);
            if (isSubscribed) is_licensed.setTrue();
        }).catch(() => isSubscribed && is_licensed.setFalse());
        return () => {
            isSubscribed = false;
            // is_licensed.setFalse();
        };
    }, [is_licensed]);

    //#region Languages
    const headerValueGetter = React.useCallback<Exclude<ColDef<A>["headerValueGetter"], string>>(params => {
        let col = params.colDef as ColDef<A>;
        if (!col) return " ";

        let parameters = col.params, code = params.colDef.headerName;
        if (!TB.validString(code)) return " ";
        else if (!parameters?.header_id) return lg.getStaticText(code, parameters?.header_template);
        else return lg.getTextObj(parameters?.header_id, code, code);
    }, [lg]);
    //#endregion

    //#region Origin
    React.useEffect(() => {
        localStorage.setItem("origin", props.origin || props.adaptableId);
        return () => localStorage.removeItem("origin");
    }, [props.origin, props.adaptableId]);

    React.useEffect(() => {
        // Removes the state saved in the local storage, so the table is always reset on opening
        return () => localStorage.removeItem(props.adaptableId || "Adaptable");
    }, [props.adaptableId]);
    //#endregion

    //#region Overlays
    React.useEffect(() => {
        let grid = adaptable.current?.agGridApi;
        if (grid && ready) {
            let has_rows = false;
            if (props.api_provided_rows) has_rows = grid?.getDisplayedRowCount?.() > 0;
            else has_rows = Array.isArray(props.rows) && props.rows.length > 0;

            // Update the rows, if the parent doesn't handle it
            if (!props.api_provided_rows) grid?.updateGridOptions?.({ rowData: props.rows });
            // Loading overlay
            if (props.status === "load" || props.loading) {
                if (typeof grid?.showLoadingOverlay === "function" && last_overlay.current !== "loading") {
                    last_overlay.current = "loading";
                    grid?.showLoadingOverlay?.();
                }
            }
            // No rows overlay
            else if (props.status === "error" || !has_rows) {
                if (typeof grid?.showNoRowsOverlay === "function" && last_overlay.current !== "no_row") {
                    last_overlay.current = "no_row";
                    grid?.showNoRowsOverlay?.();
                }
            }
            // No Overlay
            else if (typeof grid?.hideOverlay === "function" && last_overlay.current !== "none") {
                last_overlay.current = "none";
                grid?.hideOverlay?.();
            }
        }
    }, [ready, props.status, props.loading, props.rows, props.api_provided_rows]);

    React.useEffect(() => {
        if (isDark) setTimeout(() => {
            let class_name = gridContainer.current?.className || '';
            let splitted = class_name.split(" ")
                .map(str => str === "ag-theme-alpine" ? str + "-dark" : str);
            if (gridContainer.current?.className) gridContainer.current.className = splitted.join(" ");
        }, 200);
    }, [isDark]);
    //#endregion

    //#region Columns
    React.useEffect(() => {
        let grid = adaptable.current?.agGridApi;
        if (grid && ready && !grid.isDestroyed()) {
            // The updated column base definition
            let new_columns = props.columns || [];
            // The definition currently used
            let current_columns = grid?.getColumnDefs?.() || [];

            let updates = [] as TableProps<A>["columns_base"];
            if (props.columns_base === "all") updates = ["editable", "filterable", "grouped", "sortable"];
            else if (props.columns_base === "all_but_edit") updates = ["filterable", "grouped", "sortable"];
            else updates = TB.arrayWrapper(props.columns_base);

            /** Find the current grid definition */
            const find_current_def = (col: TableProps<A>["columns"][number], columns: TableProps<A>["columns"]): TableProps<A>["columns"][number] => {
                let def_col = col as ColDef<A>,
                    def_group = col as ColGroupDef<A>;

                // Col given as param is a group
                let isGroup = Array.isArray(def_group.children);

                // Loop through the columns to find a match
                for (let cd of columns) {
                    let col = cd as ColDef<A>,
                        group = cd as ColGroupDef<A>;

                    // The column currently inspected is a group
                    if (Array.isArray(group.children)) {
                        // The column from param is a group, compare headerNames
                        if (isGroup && group.headerName === def_group.headerName) return group;
                        // The column from param is not a group, search through the current group sub-menu
                        else if (!isGroup) {
                            let found_col = find_current_def(def_col, group.children);
                            // Found a match
                            if (found_col) return found_col;
                        }
                    }
                    // The column currently inspected is not a group, if column from param is not a group, compare the fields
                    else if (!isGroup && col.field === def_col.field) return col;
                }
                // No match found
                return null;
            }

            /** Update the current column */
            const update_col = (current_col: TableProps<A>["columns"][number]) => {
                let col = current_col as ColDef<A>,
                    group = current_col as ColGroupDef<A>;
                let new_updated_def = find_current_def(current_col, new_columns);

                // Found the matching column
                if (new_updated_def) {
                    // Column is a group
                    if (Array.isArray(group.children)) {
                        // Update the function to set the group name
                        group.headerValueGetter = headerValueGetter;
                        // Update the sub-columns of the group
                        group.children = group.children.map(update_col)
                            .filter(c => c !== null);
                        // Return the updated group column
                        return group;
                    }
                    // Column is not a group
                    else {
                        let type: typeof CTD[string];
                        let col_new_updated_def = new_updated_def as ColDef<A>;
                        // Find the type of the cell, if any
                        if (typeof col_new_updated_def.type === "string") type = CTD[col_new_updated_def.type];
                        // Update the header getter
                        col.headerValueGetter = headerValueGetter;
                        // Update the extra parameters
                        col.params = col_new_updated_def.params;

                        // Add the filterable option, if filter is allowed and if filter isn't already set by the type
                        if (updates.includes("filterable") && col.filter !== false && !type?.filter) col.filter = "agSetColumnFilter";
                        // Add the sortable option, if sort is allowed by the definition and the type
                        if (updates.includes("sortable") && col.sortable !== false && type?.sortable !== false) col.sortable = true;
                        // Add the group option, if group is allowed by the definition and the type
                        if (updates.includes("grouped") && col.enableRowGroup !== false && type?.enableRowGroup !== false) col.enableRowGroup = true;
                        // Add the edit option, if edit is allowed by the definition and the type
                        if (updates.includes("editable") && col.editable !== false && type?.editable !== false) col.editable = true;
                        // Forced cast, if a filter is allowed, but not defined
                        if (col.filter && !col.filter?.valueFormatter && !type?.filter) {
                            if (!col.filterParams) col.filterParams = { valueFormatter: a => String(a?.value) };
                            else col.filterParams.valueFormatter = a => String(a?.value);
                        }
                        return col;
                    }
                }
                // Props said to remove unknown column
                else if (props.remove_unknown_columns) return null;
                // This column could be created through adaptable, keep it as is
                else return current_col;
            }

            // Loop through the current columns, to keep the current order, and update their definition
            let reset_columns = (current_columns as any).map(update_col).filter(c => c !== null);

            // Find the columns that appear in the updated definition, but not in the current one
            let missing_columns = new_columns.filter(c => find_current_def(c, reset_columns) === null);
            // Update the new columns
            missing_columns = missing_columns.map(update_col);
            // Add the missing columns
            reset_columns.push(...missing_columns);
            // Empty the array without changing it's reference
            col_ref.current.splice(0, col_ref.current.length);
            // Update the ref array without changing it's reference
            col_ref.current.push(...reset_columns);
            // Update AG-Grid's columns
            grid?.updateGridOptions?.({ columnDefs: reset_columns });
        }
    }, [headerValueGetter, ready, props.columns_base, props.columns, props.remove_unknown_columns]);

    React.useEffect(() => {
        let grid = adaptable.current?.agGridApi;
        if (grid && ready && !props.loading && props.status !== "load") setTimeout(() => {
            if (!grid.isDestroyed()) {
                const fit_all = props.autoFit === true;
                if (fit_all) grid?.autoSizeAllColumns?.(true);
                else grid?.autoSizeColumns?.(TB.arrayWrapper(props.autoFit).filter(TB.validString));
            }
        }, 150);
    }, [ready, props.autoFit, props.loading, props.status]);

    React.useEffect(() => {
        let grid = adaptable.current?.agGridApi;
        if (grid && ready && !props.loading && props.status !== "load" && props.autoExpandGroups) setTimeout(() => {
            if (!grid.isDestroyed()) grid?.expandAll?.();
        }, 120);
    }, [ready, props.autoExpandGroups, props.loading, props.status]);
    //#endregion

    //#region Side bar
    const sidebar = React.useMemo<AGCommunity.SideBarDef>(() => {
        let panels: Exclude<AGCommunity.SideBarDef["toolPanels"][number], string>[] = [];

        if (typeof props.sideBar === "string") {
            if (props.sideBar === "column") panels.push(sidePanel.column);
            else if (props.sideBar === "state") panels.push(sidePanel.state);
            else if (props.sideBar === "filter") panels.push(sidePanel.filter);
            else if (props.sideBar === "filters_columns") panels.push(sidePanel.column, sidePanel.filter);
        }
        else if (props.sideBar) panels.push(sidePanel.column, sidePanel.filter, sidePanel.state);

        let panels_updated = panels.map(p => {
            let params: Partial<SBP.SideBarToolProps>;
            // Add origin and adaptable ref as parameters for custom Tools
            if (p.id !== sidePanel.column.id && p.id !== sidePanel.filter.id) params = { origin: props.adaptableId, adaptable };
            return { ...p, labelDefault: lg.getStaticText(p.labelDefault), toolPanelParams: params };
        });

        return { toolPanels: panels_updated };
    }, [lg, props.sideBar, props.adaptableId]);

    React.useEffect(() => {
        let grid = adaptable.current?.agGridApi;
        if (grid && ready && !grid.isDestroyed()) grid?.updateGridOptions?.({ sideBar: sidebar });
    }, [sidebar, ready]);
    //#endregion

    //#region Export Pre-Process
    const getFormatterDict = React.useCallback(() => {
        let grid = adaptable.current?.agGridApi;
        if (ready && grid) {
            let defs = grid?.getColumnDefs?.() || [];
            let entries = [] as [string, ColDef<A>["valueFormatter"]][];

            const searchEntries = (def: typeof defs[number]) => {
                let col = def as any as ColDef<A>;
                let group = def as any as ColGroupDef<A>;

                // Is a group columns
                if (Array.isArray(group.children)) (group.children as any).forEach(searchEntries);
                // Is a normalColumn
                else if (typeof col.valueFormatter === "function" && col.field) entries.push([col.field, col.valueFormatter]);
            }

            defs.forEach(searchEntries);
            return Object.fromEntries(entries);
        }
        else return {};
    }, [ready]);

    const processCellCallback = React.useCallback<TableProps<A>["processCellForClipboard"]>(cell => {
        let value = cell.value;
        let dict = getFormatterDict();
        let column = cell.column.getColDef() as any as ColDef<A>;

        if (typeof excel_process === "function") {
            let result = excel_process(value, column, cell.node?.data);
            if (typeof result === "string") return result;
        }

        if (column.type === CT.TYPE_FILE) return TB.arrayWrapper(cell.value).filter(TB.isFile).map(f => f.url).join(" ; ");
        else if (column.type === CT.TYPE_REG_VALIDITY_DATE || column.type === CT.TYPE_DATE) {
            let date = null, format = column.params?.format || "DD/MM/YYYY";

            if (column.type === CT.TYPE_REG_VALIDITY_DATE) {
                let validity = value as T.API.Reg.RegTableItem["validityDate"];
                if (validity?.status === "none") return lg.getStaticText(TC.REG_NO_FREQUENCY) || "none";
                else date = TB.getDate(validity?.valid);
            }
            else date = TB.getDate(cell.value);

            if (!date) return "";
            else if (typeof date === "string") return date;
            return moment(date).format(format);
        }
        else if (TB.validString(column.field)) {
            let fn = dict[column.field];
            /* @ts-ignore Does'nt want to accept that params though it worked before */
            if (typeof fn === "function") return TB.getString(fn(cell));
        }

        if (Array.isArray(value)) return value.map(str => TB.getString(str)).filter(TB.validString).join();
        else return TB.getString(value) || value?.toString?.();
    }, [getFormatterDict, excel_process, lg]);
    //#endregion

    //#region Get Context Menu Items Expanded
    const getContextMenuItemsCert = React.useMemo<GetContextMenuItems<A>>(() => {
        if (typeof getContextMenuItems !== "function") return undefined;
        return event => {
            let row = event.node?.data as A;
            let col_def = event.column?.getColDef() as any as ColDef<A>;
            let cert_params = col_def?.params?.certify;
            let field = col_def?.field as Exclude<keyof A, number | symbol>;

            if (cert_params && row) {
                let prop = field;
                let value = event.value;
                let str_value = event.value;
                let form = props.certification?.form;
                let item_id = row?.[props.certification?.item_id_prop];
                let context_menu_items: ReturnType<GetContextMenuItems<A>> = [];
                let is_empty_value = value === null || value === undefined || value === "" || (Array.isArray(value) && value.length === 0);

                if (typeof cert_params !== "boolean") {
                    // Get the string value
                    if (typeof cert_params.str_val === "function") str_value = TB.valueToString(cert_params.str_val(row));
                    else if (cert_params.str_val) str_value = TB.valueToString(row?.[cert_params.str_val]);
                    // Get the real value
                    if (typeof cert_params.value === "function") value = cert_params.value(row);
                    else if (cert_params.value) value = row?.[cert_params.value];
                    else if (cert_params.prop) value = row?.[cert_params.prop];
                    // Get the prop
                    if (typeof cert_params.prop === "string") prop = cert_params.prop as any;
                    // Get the form
                    if (typeof cert_params.form === "string") form = cert_params.form;
                    // Get the item_id
                    if (typeof cert_params.item === "string") item_id = row?.[cert_params.item];
                }

                if (prop && form && item_id) context_menu_items.push(
                    {
                        disabled: is_empty_value,
                        icon: "<i class='fa fa-stamp'></i>",
                        name: lg.getStaticText(TC.CERT_HISTORY_CERTIFY_VALUE),
                        action: () => M.renderCertForm({ form, item_id, context: props.certification?.context, prop, str_value, value }),
                    },
                    {
                        icon: "<i class='fa fa-history'></i>",
                        name: lg.getStaticText(TC.CERT_HISTORY_EDIT_HISTORY),
                        action: () => M.renderCertHistory({ item: item_id, prop }),
                    }
                );
                // Add a separator if necessary
                if (event.defaultItems?.length > 0 && context_menu_items.length > 0) context_menu_items.push("separator");
                // Update the pre-written context menu action items
                if (Array.isArray(event.defaultItems)) event.defaultItems.unshift(...context_menu_items);
                else event.defaultItems = context_menu_items;
            }
            // Call the provided function with the added certification action
            return getContextMenuItems(event);
        }
    }, [lg, getContextMenuItems, props.certification]);
    //#endregion

    //#region Grid Options
    React.useEffect(() => {
        let grid = adaptable.current?.agGridApi;
        if (ready && grid && !grid.isDestroyed()) {
            if (typeof getContextMenuItemsCert === "function") grid?.updateGridOptions?.({ getContextMenuItems: getContextMenuItemsCert });
        }
    }, [ready, getContextMenuItemsCert]);

    React.useEffect(() => {
        let grid = adaptable.current?.agGridApi;
        if (ready && grid) {
            if (typeof onValueChange === "function") onValueChange_ref.current = onValueChange;
        }
    }, [ready, onValueChange]);

    const gridProps = React.useMemo(() => {
        const removeProps: (keyof typeof props)[] = [
            "rows",
            "count",
            "status",
            "origin",
            "loading",
            "autoFit",
            "columns",
            "sideBar",
            "adaptableId",
            "columns_base",
            "adaptable_key",
            "extra_buttons",
            "certification",
            "noBottomPadding",
            "autoExpandGroups",
        ];

        return _.omit(props, removeProps);
    }, [props]);

    const statusBar = React.useMemo(() => {
        let statusBar = { statusPanels: [] } as TableProps<A>["statusBar"];

        if (typeof props.count === "boolean" || props.count === "bottom") {
            statusBar.statusPanels.push({ statusPanel: "agTotalRowCountComponent", align: "center" });
        }

        if (Object.keys(statusBar).length > 0) return statusBar;
        else return;
    }, [props.count]);

    const adaptable_dashboard_options = React.useMemo<AGR.DashboardOptions>(() => {
        let options = {
            canFloat: false,
            customToolbars: [],
            buttonsLocation: "right",
            customDashboardButtons: [],
            showQuickSearchInHeader: true,
        } as AGR.DashboardOptions;

        // Export button
        if (props.export_dashboard_button) options.customDashboardButtons.push({
            label: "XLSX",
            icon: { element: "<i class='fa fa-file-excel me-2'></i>" },
            onClick: () => adaptable.current?.agGridApi?.exportDataAsExcel?.(),
        });
        // Reload button
        if (typeof reload_button_cb === "function") options.customDashboardButtons.push({
            onClick: reload_button_cb,
            icon: { element: "<i class='fa fa-refresh'></i>" },
        });
        // A list of other buttons
        if (Array.isArray(props.extra_buttons)) options.customDashboardButtons.push(...props.extra_buttons);
        // A singular extra button
        else if (props.extra_buttons) options.customDashboardButtons.push(props.extra_buttons);
        // A list of toolbars
        if (Array.isArray(props.toolbars)) options.customToolbars.push(...props.toolbars);
        // A singular toolbar
        else if (props.toolbars) options.customToolbars.push(props.toolbars);

        return options;
    }, [props.export_dashboard_button, props.toolbars, props.extra_buttons, reload_button_cb]);

    React.useEffect(() => {
        if (adaptable_ready.value && !adaptable.current?.isDestroyed?.()) {
            let current_opt = adaptable.current?.optionsApi?.getDashboardOptions?.();
            if (typeof current_opt === "object" && current_opt !== null) {
                // Remove properties that do not exists 
                for (let key of Object.keys(current_opt)) {
                    if (adaptable_dashboard_options[key] === undefined) delete current_opt[key];
                }
                // Replace/Add properties that does
                for (let [key, value] of Object.entries(adaptable_dashboard_options)) current_opt[key] = value;
            }
            adaptable.current?.dashboardApi?.refreshDashboard?.();
        }
    }, [adaptable_ready.value, adaptable_dashboard_options]);

    const gridOptions = React.useMemo<AGR.AdaptableProviderProps<A>["gridOptions"]>(() => ({
        ...gridProps,
        statusBar,
        icons: SBP.Icons,
        // Needs to be true or 'onCellEditRequest' is not called
        readOnlyEdit: true,
        columnTypes: CTD as any,
        enableRangeSelection: true,
        suppressCutToClipboard: true,
        suppressClipboardPaste: true,
        reactiveCustomComponents: true,
        onGridReady: grid_ready.setTrue,
        suppressPropertyNamesCheck: true,
        stopEditingWhenCellsLoseFocus: true,
        /* Deleted to allow to use default sorting in column definitions (in DataChart for example) */
        // columnDefs: col_ref.current as any,
        rowGroupPanelShow: "onlyWhenGrouping",
        processCellForClipboard: processCellCallback,
        defaultCsvExportParams: { processCellCallback, author: "AISET" },
        defaultExcelExportParams: { processCellCallback, author: "AISET" },
        onCellEditRequest: params => onValueChange_ref.current?.(params as any),
        defaultColDef: { hide: false, resizable: true, headerValueGetter: headerValueGetter as any },
    }), [gridProps, statusBar, grid_ready.setTrue, processCellCallback, headerValueGetter]);

    React.useEffect(() => {
        let grid = adaptable.current?.agGridApi;
        if (grid && ready && !grid.isDestroyed()) grid?.updateGridOptions?.({ onCellEditingStarted });
    }, [ready, onCellEditingStarted]);

    React.useEffect(() => {
        let grid = adaptable.current?.agGridApi;
        if (ready && !grid.isDestroyed()) onReadyGrid?.(grid);
    }, [ready, onReadyGrid]);
    //#endregion

    return <CO.Spinner loading={!is_licensed.value}>
        <Adaptable.Provider
            modules={MODULES}
            gridOptions={gridOptions}
            onAdaptableReady={info => {
                adaptable_ready.setTrue();
                adaptable.current = info.adaptableApi;
            }}
            adaptableOptions={{
                plugins: [],
                adaptableId: props.adaptableId || "Adaptable",
                primaryKey: props.adaptable_key || 'adaptable_key',
                columnFilterOptions: { useAdaptableColumnFiltering: false },
                quickSearchOptions: { filterResultsAfterQuickSearch: true },
                autogeneratePrimaryKey: typeof props.adaptable_key !== "string",
                settingsPanelOptions: { popupType: "modal", navigation: { items: AdaptableConfigPanel } },
                predefinedConfig: { ...props.adaptable_config, Dashboard: { DashboardTitle: " ", ...props.adaptable_config?.Dashboard } },
            }}
        >
            <div className={"flex-grow-1 " + (props.noBottomPadding ? "" : "pb-3")} style={{ display: 'flex', flexFlow: 'column', height: "100%" }}>
                <Adaptable.UI />
                <div ref={gridContainer} className="ag-theme-alpine" style={{ flex: 1 }} children={<Adaptable.AgGridReact />} />
            </div>
        </Adaptable.Provider>
    </CO.Spinner>;
}

const Table = React.forwardRef(RenderTable) as <A>(props: TableProps<A> & { ref?: React.ForwardedRef<TableRef<A>> }) => React.ReactElement;
export default Table;