import React from 'react';
import { Flex } from '../Layout';
import { TB } from "../../Constants";

export type AvatarProps = {
    name?: string;
    icon?: string;
    emoji?: string;
    rounded?: string;
    isExact?: boolean;
    className?: string;
    mediaClass?: string;
    src?: string | string[];
    size?: 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
}

type AvatarGroupProps = {
    dense?: boolean;
    className?: string;
    children?: React.ReactNode | React.ReactNode[];
}

const Avatar: React.FC<AvatarProps> = props => {
    const classNames = React.useMemo(() => {
        let classes = ["avatar"];
        if (props.size) classes.push("avatar-" + props.size);
        else classes.push("avatar-xl");
        if (props.className) classes.push(props.className);
        return classes.join(' ');
    }, [props]);

    const mediaClasses = React.useMemo(() => {
        let classes = [] as string[];
        if (props.rounded) classes.push("rounded-" + props.rounded);
        else classes.push("rounded-circle");
        if (props.mediaClass) classes.push(props.mediaClass);
        return classes.join(' ');
    }, [props]);

    const avatar = React.useMemo(() => {
        if (props.src) {
            if (TB.isIterableArray(props.src)) return <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
                <div className="w-50 border-right">
                    <img src={props.src[0]} alt="" />
                </div>
                <div className="w-50 d-flex flex-column">
                    <img src={props.src[1]} alt="" className="h-50 border-bottom" />
                    <img src={props.src[2]} alt="" className="h-50" />
                </div>
            </div>;
            else return <img className={mediaClasses} src={props.src} alt="" />;
        }
        else if (props.name) return <div className={`avatar-name ${mediaClasses}`}>
            <span>{props.isExact ? props.name : props.name.match(/\b\w/g).join('')}</span>
        </div>;
        else if (props.icon) return <Flex className={`avatar-name ${mediaClasses}`}>
            <i className={'fa fa-' + props.icon} />{/*  <FA.FontAwesomeIcon icon={props.icon} /> */}
        </Flex>;
        else return <div className={`avatar-emoji ${mediaClasses}`}>
            <span role="img" aria-label="Emoji">
                {props.emoji}
            </span>
        </div>
    }, [mediaClasses, props]);

    return <div className={classNames}>{avatar}</div>;
};

export const AvatarGroup: React.FC<AvatarGroupProps> = props => {
    const classes = React.useMemo(() => {
        let classes = ["avatar-group"];
        if (props.className) classes.push(props.className);
        if (props.dense) classes.push("avatar-group-dense");
        return classes.join(" ");
    }, [props]);

    return <div className={classes}>{props.children}</div>;
};

export default Avatar;
