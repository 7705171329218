import Sentry from "../Sentry";
import * as S from "../services";
import { thunk } from "redux-thunk";
import { FP, T } from "../Constants";
import rootReducer from "../reducers";
import asyncDispatch from "./asyncDispatch";
import { legacy_createStore, applyMiddleware } from "redux";

const transform_context = (context: T.DataContextRedux): T.ContextParams => {
    let search_context = {} as T.ContextParams;
    if (context.selectedPortfolio) search_context.portfolio = context.selectedPortfolio;
    else search_context.roots = context.selectedItems;
    return search_context;
}

const create_redux_store = async () => {
    // First load the data then create the store
    const default_data_context = new Promise<T.DataContextRedux>(resolve => {
        try {
            // Check if there is a context stored in localStorage
            let stored = localStorage.getItem("redux_context");
            if (typeof stored === "string" && stored.length > 0) {
                let store = JSON.parse(stored) as T.DataContextRedux;
                const is_on_mission_page = window.location.pathname.includes("/mission/");

                // Update the Sentry context
                Sentry.setExtras({
                    roots: store.selectedItems[0],
                    portfolio_id: store.selectedPortfolio || null,
                    mission_id: is_on_mission_page ? store.active_mission || null : null,
                });
                // Update the state and the sessionStorage
                if (!is_on_mission_page) {
                    delete store.active_mission;
                    sessionStorage.removeItem("mission_id");
                }
                else if (store.active_mission) {
                    store.selectedItems = [store.mission_asset];
                    sessionStorage.setItem("mission_id", store.active_mission);
                    sessionStorage.setItem("mission_asset", store.mission_asset);
                }
                resolve(store);
            }
            // If user is logged, try to load a default context
            else {
                // Load the user from the storage
                let user = localStorage.getItem("formioUser");

                try {
                    const user_data: T.Submission<T.UserData> = JSON.parse(user);
                    if (user_data?._id) Sentry.setUser({ id: user_data._id });
                }
                catch (error) {
                    console.log(error);
                }

                // Contact the database
                if (typeof user === "string" && user.length > 0) S.defaultContext().then(response => {
                    // A default context was found
                    if (response.data) {
                        // Update the Sentry context
                        Sentry.setExtras({ portfolio_id: response.data.portfolio || null, mission_id: null, roots: response.data.roots || null });
                        // Update the state
                        resolve({
                            mission_asset: null,
                            active_mission: null,
                            selectedItems: response.data.roots || [],
                            selectedPortfolio: response.data.portfolio || null,
                            selectedSites: response.data.path === FP.SITE_FORM ? response.data.roots : [],
                            selectedClients: response.data.path === FP.CLIENT_FORM ? response.data.roots : [],
                            selectedBuildings: response.data.path === FP.BUILDING_FORM ? response.data.roots : [],
                            selectedEmplacements: response.data.path === FP.EMPLACEMENT_FORM ? response.data.roots : [],
                        });
                    }
                    else resolve(undefined);
                }).catch(() => resolve(undefined));
                else resolve(undefined);
            }
        }
        catch (error) { resolve(undefined) }
    });
    // Pre-load the routes states
    const routes_states_promise = new Promise<T.RoutesStatesRedux>(resolve => {
        if (window.location.pathname === "/login") resolve([]);
        else S.getRoutesTableStates()
            .then(({ data }) => resolve(data))
            .catch(() => resolve([]));
    });
    // Second load the rights for the context
    const default_right_context = (context: T.DataContextRedux) => new Promise<T.RightsContextRedux>(resolve => {
        if (!context) resolve(undefined);
        else {
            let search_context = transform_context(context);
            if (window.location.pathname === "/login") resolve({ access: [], admin: false, context_ids: [], first: false, search_context, loading: false });
            else S.getUserAccess(search_context)
                .then(({ data }) => resolve({ ...data, search_context, loading: false }))
                .catch(() => resolve({ access: [], admin: false, context_ids: [], first: false, search_context, loading: false }));
        }
    });

    let [dataContext, routes_states] = await Promise.all([default_data_context, routes_states_promise]);
    // Create the promises for the rights
    let rights_context = await default_right_context(dataContext);
    const store = legacy_createStore(rootReducer, { dataContext, rights: rights_context, routes_states }, applyMiddleware(...middleware));
    return store;
}

const middleware = [thunk, asyncDispatch];
const store = create_redux_store();
export default store;