// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticketPage {
  height: 100%;
}
.ticketPage .myCard {
  height: 97.5%;
  width: 95%;
  max-width: 500px;
  max-height: 650px;
  margin: auto;
  box-shadow: 0 0 7px rgba(38, 38, 38, 0.3);
  border-radius: 5px;
}
.ticketPage .card-footer {
  min-height: 50px !important;
}
.ticketPage .card-footer button {
  float: right;
  margin: 0 !important;
  margin-right: 10px !important;
}
.ticketPage a {
  margin-left: 10px;
  float: left;
  text-align: center;
  color: #3973ac !important;
  font-weight: normal;
  font-size: 14px;
}
.ticketPage a:hover {
  cursor: pointer;
}
.ticketPage .myValue {
  display: block;
}
.ticketPage .myValue input {
  width: 100%;
}
.ticketPage button.btn.btn-primary.btn-md {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Events_Tickets_QR/Ticket.scss","webpack://./src/variable.scss"],"names":[],"mappings":"AACA;EACI,YAAA;AAAJ;AACI;EACI,aAAA;EACA,UAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,yCCYM;EDXN,kBAAA;AACR;AACI;EACI,2BAAA;AACR;AAAQ;EACI,YAAA;EACA,oBAAA;EACA,6BAAA;AAEZ;AACI;EACI,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;AACR;AAAQ;EACI,eAAA;AAEZ;AAEI;EACI,cAAA;AAAR;AACQ;EACI,WAAA;AACZ;AAEI;EACI,aAAA;AAAR","sourcesContent":["@import \"../../variable.scss\";\n.ticketPage{\n    height: 100%;\n    .myCard{\n        height: 97.5%;\n        width: 95%;\n        max-width: 500px;\n        max-height: 650px;\n        margin: auto;\n        box-shadow: $cart-shadow;\n        border-radius: 5px;\n    }\n    .card-footer{\n        min-height: 50px !important;\n        button{\n            float: right;\n            margin: 0 !important;\n            margin-right: 10px !important;\n        }\n    }\n    a{\n        margin-left: 10px;\n        float: left;\n        text-align: center;\n        color: $base !important;\n        font-weight: normal;\n        font-size: 14px;\n        &:hover{\n            cursor: pointer;\n        }\n    }\n    \n    .myValue{\n        display: block;\n        input{\n            width: 100%;\n        }\n    }\n    button.btn.btn-primary.btn-md{\n        display: none;\n    }\n}\n","$base-white: #ebf0fa;\r\n$base-light: #8cb3d9;\r\n$base: #3973ac;\r\n$base-dark: #264d73;\r\n\r\n$bleu: #00aaff;\r\n$bleu-dark: #0099e6;\r\n$purple: #9999ff;\r\n$purple-dark: #8080ff;\r\n$red: #ff6666;\r\n$red-dark: #ff4d4d;\r\n$yellow: #ffcc66;\r\n$yellow-dark: #ffc34d;\r\n$green: #00cc66;\r\n$green-dark: #00b359;\r\n\r\n$white: #ffffff;\r\n$grey-light: #bfbfbf;\r\n$grey: #909090;\r\n$black: #262626;\r\n\r\n$cart-shadow: 0 0 7px rgba($black, 0.3);\r\n$btn-shadow: 0 0 6px rgba($black, 0.2);\r\n$bigBox-shadow : 0 0 22px rgba($black, 0.3);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
