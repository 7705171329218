// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#formInfosDiv {
  width: 100%;
  margin-bottom: 15px;
}
#formInfosDiv .formInfosClass {
  width: 100%;
  padding: 15px;
}
#formInfosDiv .formInfosClass label {
  width: 15%;
  display: inline-block;
}
#formInfosDiv .formInfosClass input {
  width: 85%;
}
#formInfosDiv #errorMessage {
  width: 25%;
  margin: 0px auto;
  text-align: center;
}
#formInfosDiv #errorMessage span {
  font-size: larger;
  font-weight: bold;
}
#formInfosDiv.error {
  border: 2px solid rgba(255, 0, 0, 0.473);
  background-color: rgba(255, 0, 0, 0.199);
  border-radius: 15px;
}

.btn.btn-primary.btn-sm.btn-block.formcomponent.drag-copy {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/newFormio/FormViewer.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;AACF;AAAE;EACE,WAAA;EACA,aAAA;AAEJ;AADI;EACE,UAAA;EACA,qBAAA;AAGN;AADI;EACE,UAAA;AAGN;AACE;EACE,UAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACI;EACE,iBAAA;EACA,iBAAA;AACN;AAGE;EACE,wCAAA;EACA,wCAAA;EACA,mBAAA;AADJ;;AAKA;EACE,WAAA;AAFF","sourcesContent":["#formInfosDiv {\n  width: 100%;\n  margin-bottom: 15px;\n  .formInfosClass {\n    width: 100%;\n    padding: 15px;\n    label {\n      width: 15%;\n      display: inline-block;\n    }\n    input {\n      width: 85%;\n    }\n  }\n\n  #errorMessage {\n    width: 25%;\n    margin: 0px auto;\n    text-align: center;\n\n    span {\n      font-size: larger;\n      font-weight: bold;\n    }\n  }\n\n  &.error {\n    border: 2px solid rgba(255, 0, 0, 0.473);\n    background-color: rgba(255, 0, 0, 0.199);\n    border-radius: 15px;\n  }\n}\n\n.btn.btn-primary.btn-sm.btn-block.formcomponent.drag-copy{\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
