/** The IP of the current machine */
export const APP_IP_SHORT = "172.16.121.175";
/** The IP of the Agilytics machine */
export const AGILYTICS_IP = "http://172.16.122.222:5001";
/** The main domain of the app */
export const APP_DOMAIN = "https://dev.aiset.io";
/** The IP of the Superset instance */
export const SUPERSET_IP = "http://172.16.120.186";
/** The ID of the admin bot */
export const ADMIN_BOT_ID = "61a5ec3b38ccdf0846fcadc0";
/** The domain that routes back to the Superset instance */
export const SUPERSET_RE_ROUTED = "https://superset.aiset.io";
/** The IP of the Session manager for Superset */
export const SUPERSET_SESSION_IP = SUPERSET_IP + ":8810";
/** The URL for the data API */
export const DATA_QUERY_URL = "http://172.16.120.80:43585/query";
/** The address for the report data reformulation API */
export const REFORMULATE_URL = "http://172.16.122.202:3000/";
/** The address for the Sankey graph API TODO 5007 for Prod */
export const SANKEY_URL = "http://172.16.122.222:5008/api/graph_verification";
/** The Sentry DSN url (is null for dev) */
export const SENTRY_DSN = "";
// The IP of the current machine, used to allow other services to connect to it
export const IP_DIGITS = APP_IP_SHORT.split('.').slice(-1)[0];
/** The database IP's last digits */
export const DB_DIGITS = "101";
/** The current environment */
const APP_ENVIRONMENT = "DEV";
/** Shorthand to know which environment we are in */
export const IS_DEV = APP_ENVIRONMENT === "DEV";
export const IS_PROD = APP_ENVIRONMENT === "PROD";
export const IS_STAGING = APP_ENVIRONMENT === "STAGING";
/** What to store in the entry_edit table based on the environment */
export const ENTRY_EDIT_ENVIRONMENT = IS_DEV ? "75" : "95";