import _ from "lodash";
import Flex from "./Flex";
import React from "react";
import * as I from "../Item";
import { Grid } from "@material-ui/core";
import { T, TB, TC } from "../../Constants";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { useDark, useLanguage, usePagination } from "../../hooks";

//#region Types
type RowFormatProps = "xs" | "sm" | "md" | "lg" | "xl";
type RowFormat = { [key in RowFormatProps]?: RowFormatValues };
type ButtonStyle = { style?: React.CSSProperties, color?: "info" };
export type GridCat = { value: string, icon: string, title?: string };
type RowFormatValues = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type ButtonsProps = { label: string, icon: string, action: () => void, variant?: T.ColorTypes, hide?: boolean };

type AdvancedGridProps<E = unknown> = {
    elements: E[];
    label?: string;
    pageLimit?: number;
    rowFormat?: RowFormat;
    emptyGridMsg?: string;
    exclusiveCat?: boolean;
    categories?: GridCat[];
    optionsLoading?: boolean;
    buttons?: ButtonsProps[];
    refreshOptions?: () => void;
    extraComponent?: React.ReactNode;
    defaultCategory?: string | string[];
    renderElement: (element: E, index: number) => React.ReactNode;
    categoryFilter?: (element: E, categories: string[]) => boolean;
}
//#endregion

//#region Constants
const getDefaultCat = (defaults?: string | string[], categories?: GridCat[]) => {
    if (defaults) return TB.arrayWrapper(defaults).filter(TB.validString);
    if (categories) return TB.getArray(categories).map(c => c?.value).filter(TB.validString);
    return [];
}
//#endregion

const AdvancedGrid = <E,>({
    renderElement, categoryFilter, refreshOptions,
    defaultCategory, categories, buttons, elements,
    rowFormat, extraComponent, label, pageLimit,
    exclusiveCat, emptyGridMsg, optionsLoading
}: AdvancedGridProps<E>) => {
    const [sCategories, setCategories] = React.useState<string[] | null>(getDefaultCat(defaultCategory, categories));

    const filteredElements = React.useMemo(() => {
        if (typeof categoryFilter !== "function") return elements;
        return elements.filter(e => categoryFilter(e, sCategories));
    }, [elements, sCategories, categoryFilter]);

    const isDark = useDark();
    const { getStaticText } = useLanguage();
    const { pagination, currentPage, pageSize } = usePagination({ initialPageSize: pageLimit, totalItems: filteredElements?.length || 0 });

    //#region Categories
    const onChangeCategory = React.useCallback((e: any, val: string[]) => setCategories(TB.arrayWrapper(val).filter(val => val !== 'reload')), []);
    const buttonStyle = React.useMemo<ButtonStyle>(() => isDark ? { style: { borderColor: "white", color: "white" }, color: "info" } : {}, [isDark]);

    const cCategories = React.useMemo(() => {
        if ((!Array.isArray(categories) || categories.length <= 1) && typeof refreshOptions !== "function") return null;
        return <ToggleButtonGroup exclusive={exclusiveCat} value={sCategories} onChange={onChangeCategory}>
            {typeof refreshOptions === "function" && <ToggleButton onClick={refreshOptions} sx={buttonStyle.style} title={getStaticText(TC.HOME_RELOAD_TITLE)} value="reload">
                <i className={"fa fa-refresh " + (optionsLoading ? "fa-spin" : "")}></i>
            </ToggleButton>}

            {TB.getArray(categories).map(c => <ToggleButton key={c.value} sx={buttonStyle.style} color={buttonStyle.color} title={getStaticText(c.title)} value={c.value}>
                <i className={`fa fa-${c.icon}`}></i>
            </ToggleButton>)}
        </ToggleButtonGroup>
    }, [categories, exclusiveCat, optionsLoading, sCategories, buttonStyle, getStaticText, onChangeCategory, refreshOptions]);
    //#endregion

    //#region Buttons
    const cButtons = React.useMemo(() => {
        if (!Array.isArray(buttons) || buttons.length === 0) return null;
        return <ButtonGroup>
            {buttons.filter(b => !b.hide).map((b, i) => <I.Button
                key={i}
                icon="plus"
                text={b.label}
                variant={b.variant}
                onClick={() => b?.action?.()}
            />)}
        </ButtonGroup>
    }, [buttons]);
    //#endregion

    //#region Elements
    const currentElements = React.useMemo(() => _.slice(filteredElements, currentPage * pageSize, (currentPage + 1) * pageSize), [filteredElements, currentPage, pageSize]);
    //#endregion

    return <Row className="g-2 align-items-center">
        <Col md={12}>
            <Flex alignItems="center" justifyContent="between">
                <p className="h4" children={getStaticText(label)} />
                {pagination}
                {cButtons}
                {cCategories}
            </Flex>
        </Col>
        {extraComponent && <Col md={12}>
            {extraComponent}
        </Col>}
        <Col md={12}>
            {currentElements.length > 0 && <Grid container spacing={1}>
                {currentElements.map((elem, i) => <Grid key={i} item {...rowFormat}>
                    {renderElement?.(elem, i)}
                </Grid>)}
            </Grid>}

            {currentElements.length === 0 && <Flex justifyContent="center" alignItems="center" className="py-4">
                <span className="fst-italic" children={getStaticText(emptyGridMsg)} />
            </Flex>}
        </Col>
    </Row>
}

export default AdvancedGrid;