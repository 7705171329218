/** The tech package */
export const TECH = "TECH_BASE";
/** The reglementation package */
export const REG = "REG_BASE";
/** The energy package */
export const NRJ = "NRG_BASE";
/** The action package */
export const ACTION = "ACTION_BASE";
/** A Misc package */
export const MISC = "MISC";
/** The Mission package */
export const MISSION = "MISSION";
/** Package for actions for users that are not admins, but almost */
export const SUB_ADMIN = "SUB_ADMIN";