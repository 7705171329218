import axios from "axios";
import { getHeader } from "../Headers";
import { T, APIS } from "../../Constants";

const { EDL_API } = APIS;

type RemoveRemarques = T.API.ToAxios<T.API.EDL.RemoveRemarques>;
type RemoveEquipments = T.API.ToAxios<T.API.EDL.RemoveEquipments>;
type DeleteStoreEquip = T.API.ToAxios<T.API.EDL.DeleteStoreEquip>;
type SetElemIndicator = T.API.ToAxios<T.API.EDL.SetElemIndicator>;
type GetRemarquesItems = T.API.ToAxios<T.API.EDL.GetRemarquesItems>;
type EditRemarqueField = T.API.ToAxios<T.API.EDL.EditRemarqueField>;
type GetContractsItems = T.API.ToAxios<T.API.EDL.GetContractsItems>;
type ReassignGamme = T.API.ToAxios<T.API.Utils.Gammes.ReassignGamme>;
type TagMapperData = T.API.ToAxios<T.API.Utils.Energy.TagMapperData>;
type RemoveEmplacements = T.API.ToAxios<T.API.EDL.RemoveEmplacements>;
type GetStoreEquipItems = T.API.ToAxios<T.API.EDL.GetStoreEquipItems>;
type GetIndicators = T.API.ToAxios<T.API.EDL.EQUIP_DATA.GetIndicators>;
type GetElementRemarques = T.API.ToAxios<T.API.EDL.GetElementRemarques>;
type GetEquipmentStates = T.API.ToAxios<T.API.EDL.EQUIP_DATA.GetStates>;
type IsOmniclassUsed = T.API.ToAxios<T.API.Utils.Gammes.IsOmniclassUsed>;
type CreateEditGamme = T.API.ToAxios<T.API.Utils.Gammes.CreateEditGamme>;
type CheckCorrection = T.API.ToAxios<T.API.Utils.Energy.CheckCorrection>;
type GetGammesOptions = T.API.ToAxios<T.API.Utils.Gammes.GetGammesOptions>;
type ToggleEquipGamme = T.API.ToAxios<T.API.Utils.Gammes.ToggleEquipGamme>;
type ListStationsTags = T.API.ToAxios<T.API.Utils.Energy.ListStationsTags>;
type GetActionsForRemarque = T.API.ToAxios<T.API.EDL.GetActionsForRemarque>;
type GetGammeBaseInfos = T.API.ToAxios<T.API.Utils.Gammes.GetGammeBaseInfos>;
type AutoFillTagMapper = T.API.ToAxios<T.API.Utils.Energy.AutoFillTagMapper>;
type AssessCorrections = T.API.ToAxios<T.API.Utils.Energy.AssessCorrections>;
type TreeFiltersGammes = T.API.ToAxios<T.API.Utils.Gammes.TreeFiltersGammes>;
type TagMapperValidate = T.API.ToAxios<T.API.Utils.Energy.TagMapperValidate>;
type StoreEquipToConformity = T.API.ToAxios<T.API.EDL.StoreEquipToConformity>;
type GetGammesFromParent = T.API.ToAxios<T.API.Utils.Gammes.GetGammesFromParent>;
type GetCriticityLevels = T.API.ToAxios<T.API.EDL.EQUIP_DATA.GetCriticityLevels>;
type GetStoreEquipItemsFromIds = T.API.ToAxios<T.API.EDL.GetStoreEquipItemsFromIds>;
type GetGammesOptionsLight = T.API.ToAxios<T.API.Utils.Gammes.GetGammesOptionsLight>;
type CanUserReadFromQrCode = T.API.ToAxios<T.API.EDL.EQUIP_SHEET.CanUserReadFromQrCode>;
type UpdateGammesInheritance = T.API.ToAxios<T.API.Utils.Gammes.UpdateGammesInheritance>;
type GetEquipmentSheetAccess = T.API.ToAxios<T.API.EDL.EQUIP_SHEET.GetEquipmentSheetAccess>;
type GetPreciseEquipIndicator = T.API.ToAxios<T.API.EDL.EQUIP_DATA.GetPreciseEquipIndicator>;

/** Load contracts table items */
export const getContractsItems: GetContractsItems = context => axios.post(EDL_API + "contracts", context);
/** Load remarques table items */
export const getRemarquesItems: GetRemarquesItems = params => axios.post(EDL_API + "remarques", params);
/** Load Equipment Store table items from context */
export const getStoreEquipItems: GetStoreEquipItems = context => axios.post(EDL_API + "equip_store", context);
/** Transform old store equipments to a new format */
export const storeEquipToConformity: StoreEquipToConformity = () => axios.post(EDL_API + "conformity/equip_store", getHeader("ADMIN"));
/** Load Equipment Store table items from equipstore's ids */
export const getStoreEquipItemsFromIds: GetStoreEquipItemsFromIds = ids => axios.post(EDL_API + "ids/equip_store", { ids });
/** Removes storeEquipments and disaffiliate the equipments links to them */
export const deleteStoreEquip: DeleteStoreEquip = ids => axios.post(EDL_API + "delete/equip_store", { ids }, getHeader("DELETE"));
/** Load the remarques associated to an element */
export const getElementRemarques: GetElementRemarques = id => axios.post(EDL_API + "element/remarques", { id });
/** Remove one or more remarques */
export const removeRemarques: RemoveRemarques = ids => axios.post(EDL_API + "remove/remarques", { ids }, getHeader("DELETE"));
/** Load the actions associated for a remarque */
export const getActionsForRemarque: GetActionsForRemarque = params => axios.post(EDL_API + "remarque/actions", params);
/** Get a list of equipment indicator of a certain type */
export const getPreciseEquipIndicator: GetPreciseEquipIndicator = type => axios.get(EDL_API + "indicators", { params: { type } });
/** Get all equipment's indicators */
export const getEquipIndicators: GetIndicators = () => axios.get(EDL_API + "all_indicators");
/** Get all equipment's states */
export const getEquipmentStates: GetEquipmentStates = () => axios.get(EDL_API + "states");
/** Get equipment's criticity levels */
export const getCriticityLevels: GetCriticityLevels = () => axios.get(EDL_API + "criticity");
/** Set an indicator value */
export const setElemIndicator: SetElemIndicator = params => axios.post(EDL_API + "set/indicators", params, getHeader("EDIT"));
/** Find an equipment and check user's access for the equipment */
export const getEquipmentSheetAccess: GetEquipmentSheetAccess = params => axios.post(EDL_API + "sheet/access", params);
/** Remove one or many equipment */
export const removeEquipments: RemoveEquipments = ids => axios.post(EDL_API + "remove/equipment", { ids }, getHeader("DELETE"));
/** Remove one or many emplacements */
export const removeEmplacements: RemoveEmplacements = ids => axios.post(EDL_API + "remove/emplacement", { ids }, getHeader("DELETE"));
/** Get gammes as options, and only the gammes that can be equipments **/
export const getGammesOptionsLight: GetGammesOptionsLight = () => axios.get(EDL_API + "gammes/light/options");
/** Get gammes as options - with parent's id and isEquipment flag */
export const getGammesOptions: GetGammesOptions = params => axios.get(EDL_API + "gammes/options", { params });
/** Get the essential to setup the tree filters for the gammes */
export const treeFiltersGammes: TreeFiltersGammes = () => axios.get(EDL_API + "gammes/filters");
/** Get gammes options descendant from a gamme parent, only those that can be equipments */
export const getGammesFromParent: GetGammesFromParent = params => axios.get(EDL_API + "gammes/descendants", { params });
/** Get a list of tags for a station, the datasets they're linked to, and the available options to link them to through a datasets */
export const tagMapperData: TagMapperData = params => axios.post(EDL_API + "tag/mapper", params);
/** Run a script to auto-fill the tag mapper */
export const autoFillTagMapper: AutoFillTagMapper = params => axios.post(EDL_API + "tag/mapper/auto_fill", params);
/** Update and/or Create multiple datasets */
export const tagMapperValidate: TagMapperValidate = datasets => axios.post(EDL_API + "tag/validate", { datasets });
/** Edit a remarque value from the remarques table */
export const update_remarque_field: EditRemarqueField = params => axios.post(EDL_API + "edit/remarque", params, getHeader("EDIT"));
/** Activate / Deactivate an equip_gamme */
export const toggleEquipGamme: ToggleEquipGamme = params => axios.post(EDL_API + "toggle", params, getHeader("EDIT"));
/** Update all the gammes to respect the principles of inheritance */
export const updateGammesInheritance: UpdateGammesInheritance = () => axios.post(EDL_API + "gammes/inheritance", getHeader("EDIT"));
/** Get a list of tags / stations, can be restricted to a context, or not */
export const listStationsTags: ListStationsTags = context => axios.post(EDL_API + "stations/tags", context);
/** Launch a script to test correction of data for pairs of stations/tags */
export const checkCorrection: CheckCorrection = pairs => axios.post(EDL_API + "correct_pairs", { pairs });
/** Validate or reject the correction suggested */
export const assessCorrections: AssessCorrections = params => axios.post(EDL_API + "assess_corrections", params);
/** Check if the user can access some properties for an equipment, based on it's QrCode */
export const canUserReadFromQrCode: CanUserReadFromQrCode = qrCode => axios.post(EDL_API + "sheet/can_read", { qrCode });
/** Reassign an existing gamme into another one, and delete the former */
export const reassignGamme: ReassignGamme = params => axios.post(EDL_API + "reassign/gamme", params, getHeader("EDIT"));
/** Get the basic infos of a gamme */
export const getGammeBaseInfos: GetGammeBaseInfos = gamme => axios.post(EDL_API + "gamme/infos", { gamme });
/** Check if an omniclass is already used */
export const isOmniclassUsed: IsOmniclassUsed = params => axios.get(EDL_API + "omniclass/used", { params });
/** Create or edit a gamme */
export const createEditGamme: CreateEditGamme = params => axios.post(EDL_API + "create/gamme", params, getHeader("EDIT"));