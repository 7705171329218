// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loadingQR {
  width: -moz-fit-content;
  width: fit-content;
  margin: 40vh auto;
}`, "",{"version":3,"sources":["webpack://./src/Components/Events_Tickets_QR/QRCode.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EAAA,kBAAA;EACA,iBAAA;AACJ","sourcesContent":[".loadingQR{\n    width: fit-content;\n    margin: 40vh auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
